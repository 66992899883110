define("discourse/plugins/discourse-experiences/discourse/templates/experiences", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class='user-content-wrapper'>
    <section class="user-primary-navigation">
  
      {{#mobile-nav class='main-nav' desktopClass="nav nav-pills user-nav"}}
  
        <!--      <li>-->
        <!--        <a href="/postes">ES投稿-->
        <!--        </a>-->
        <!--      </li>-->
        <!--      <li>-->
        <!--        <a href="/showes">ES閲覧-->
        <!--        </a>-->
        <!--      </li>-->
  
        <li class="post_experiences"><LinkTo @route='postes'>ES投稿</LinkTo></li>
        <li class="show_experiences"><LinkTo @route='showes'>ES閲覧</LinkTo></li>
  
      {{/mobile-nav}}
    </section>
    <div class="side-bar">
  
    </div>
  </div>
  
  */
  {
    "id": "HGgk1uBJ",
    "block": "[[[10,0],[14,0,\"user-content-wrapper\"],[12],[1,\"\\n  \"],[10,\"section\"],[14,0,\"user-primary-navigation\"],[12],[1,\"\\n\\n\"],[6,[39,2],null,[[\"class\",\"desktopClass\"],[\"main-nav\",\"nav nav-pills user-nav\"]],[[\"default\"],[[[[1,\"\\n      \"],[3,\"      <li>\"],[1,\"\\n      \"],[3,\"        <a href=\\\"/postes\\\">ES投稿\"],[1,\"\\n      \"],[3,\"        </a>\"],[1,\"\\n      \"],[3,\"      </li>\"],[1,\"\\n      \"],[3,\"      <li>\"],[1,\"\\n      \"],[3,\"        <a href=\\\"/showes\\\">ES閲覧\"],[1,\"\\n      \"],[3,\"        </a>\"],[1,\"\\n      \"],[3,\"      </li>\"],[1,\"\\n\\n      \"],[10,\"li\"],[14,0,\"post_experiences\"],[12],[8,[39,4],null,[[\"@route\"],[\"postes\"]],[[\"default\"],[[[[1,\"ES投稿\"]],[]]]]],[13],[1,\"\\n      \"],[10,\"li\"],[14,0,\"show_experiences\"],[12],[8,[39,4],null,[[\"@route\"],[\"showes\"]],[[\"default\"],[[[[1,\"ES閲覧\"]],[]]]]],[13],[1,\"\\n\\n\"]],[]]]]],[1,\"  \"],[13],[1,\"\\n  \"],[10,0],[14,0,\"side-bar\"],[12],[1,\"\\n\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"div\",\"section\",\"mobile-nav\",\"li\",\"link-to\"]]",
    "moduleName": "discourse/plugins/discourse-experiences/discourse/templates/experiences.hbs",
    "isStrictMode": false
  });
});